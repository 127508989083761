/**
 * Events
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$event-badge-default-color        : $color-black !default;
$event-badge-default-bg-color     : $brand-success !default;
$event-badge-waiting-bg-color     : $brand-warning !default;
$event-badge-fullybooked-bg-color : $brand-danger !default;
$event-badge-border-radius        : 3px !default;
$event-data-name-size             : 40% !default;

$event-indicator-border-color : $brand-tertiary !default;
$event-indicator-border-width : 4px;
$event-indicator-size         : 24px;

@keyframes load-more-rotate {
	0% {
		transform : translate3d(-50%, -50%, 0) rotate(0deg);
	}

	100% {
		transform : translate3d(-50%, -50%, 0) rotate(360deg);
	}
}

/** ----------------------------------------------------------------------------
 * Kontrollbar
 * -------------------------------------------------------------------------- */

@keyframes ec-loading-indicator {
	0% { transform : rotate(0deg); }

	100% { transform : rotate(360deg); }
}

.event-control {
	display         : flex;
	flex-flow       : row nowrap;
	font-size       : 1rem;
	justify-content : space-between;

	&__item {
		flex       : 0 1 auto;
		text-align : right;

		&:first-child {
			text-align : left;
		}
	}

	&__item-link {
		background-color : $btn-secondary-bg-color;
		border-radius    : 2em;
		border           : $btn-border-width $btn-border-style $btn-secondary-border-color;
		color            : $btn-primary-color;
		display          : block;
		font-size        : cast-unit('em', $font-size-base);
		font-weight      : $font-weight-light;
		line-height      : 1;
		padding          : cast-unit('em', $gap-base-small) cast-unit('em', $gap-base);
		text-decoration  : none;

		&:hover,
		&:focus {
			background-color : $btn-secondary-bg-color-hover;
			border-color     : $btn-secondary-border-color-hover;
			color            : $btn-secondary-color-hover;
			text-decoration  : none;
		}

		// Warenkorb-Button (add und remove)
		&[data-control="add-basket"] {
		}

		// Entfernen-Button(s)
		&[data-control|="remove"] {
			background-color : $btn-default-bg-color;
			border-color     : $btn-default-border-color;
			color            : $btn-default-color;
			display          : none;

			&:hover,
			&:focus {
				background-color : $btn-default-bg-color-hover;
				border-color     : $btn-default-border-color-hover;
				color            : $btn-default-color-hover;
			}
		}

		// Warteliste-Button (add und remove)
		&[data-control="add-waitinglist"] {
			background-color : $btn-warning-bg-color;
			border-color     : $btn-warning-border-color;
			color            : $btn-warning-color;
			display          : none;

			&:hover,
			&:focus {
				background-color : $btn-warning-bg-color-hover;
				border-color     : $btn-warning-border-color-hover;
				color            : $btn-warning-color-hover;
			}
		}

		&[data-control="detail"] {
			background-color : transparent;
			border-color     : $btn-default-bg-color;
			color            : $btn-default-bg-color;

			&:hover,
			&:focus {
				background-color : transparent;
				border-color     : $btn-default-bg-color-hover;
				color            : $btn-default-bg-color-hover;
			}
		}

		// Standardmäßig AddBasket aktiv!
		// Spez. Anzeigen kommen über Status des Events!
		&[data-control="add-basket"] {
			display : block;
		}

		&[data-control="remove-basket"] {
			display : none;
		}
	}

	&.-is-loading {
		.event-control__item {
			opacity : 0.1;
		}

		&::before {
			content          : "";
			position         : absolute;
			top              : 0;
			right            : 0;
			bottom           : 0;
			left             : 0;
			background-color : transparent;
			z-index          : 10;
		}

		&::after {
			$-size   : cast-unit($font-unit-base, $event-indicator-size);
			$-offset : cast-unit($font-unit-base, ceil($event-indicator-size / 2));

			animation          : ec-loading-indicator 0.8s infinite linear;
			border             : cast-unit($font-unit-base, $event-indicator-border-width) solid $event-indicator-border-color;
			border-radius      : 50%;
			border-right-color : transparent !important;
			content            : '';
			height             : $-size;
			left               : 50%;
			margin             : -#{$-offset} 0 0 -#{$-offset};
			position           : absolute;
			top                : 50%;
			width              : $-size;
			z-index            : 11;
		}
	}
}

/** ----------------------------------------------------------------------------
 * Block (Box)
 * -------------------------------------------------------------------------- */

.event {
	background-color : $color-white;
	cursor           : default;
	display          : flex;
	flex-flow        : column nowrap;
	font-size        : 1rem;
	position         : relative;

	> :first-child {
		margin-top : 0;
	}

	> :last-child {
		margin-bottom : 0;
	}
	
	&.-sponsor {
		border : 3px solid $brand-tertiary !important;
		
		//&::after {
		//	background : $brand-tertiary;
		//	color      : $color-white;
		//	content    : 'Anzeige';
		//	display    : block;
		//	font-size  : 0.7rem;
		//	padding    : 2px 10px;
		//	position   : absolute;
		//	right      : -3px;
		//	top        : -8px;
		//	z-index    : 11;
		//}
	}

	/**
	 * Elemente
	 */

	&__title {
		hyphens : auto;
	}

	// visueller Status
	&__badge {
		&:empty {
			display : none;
		}

		background-color : $event-badge-default-bg-color;
		border-radius    : cast-unit($font-unit-base, $event-badge-border-radius);
		color            : $event-badge-default-color;
		padding-left     : 1.5em;
		padding-right    : 1.5em;
	}

	// Daten
	&__data {
		align-content   : stretch;
		align-items     : stretch;
		display         : flex;
		flex-direction  : row;
		flex-wrap       : wrap;
		font-size       : cast-unit('rem', $gap-base);
		justify-content : flex-start;
		margin-bottom   : $gap-base;
		margin-top      : $gap-base;

		&.-dates {
			background : #f4f4f4;
			padding    : $gap-base;

			& + .-dates {
				margin-top : $gap-base-large;
			}
		}

		&-date {
			flex       : 100%;
			margin-top : 0;

			> * {
				font-weight : 700;
				margin-top  : 0;
			}

			i {
				margin-right : $gap-base-small;
				position     : relative;
				top          : -1px;
			}
		}

		&-name,
		&-value {
			align-self  : auto;
			font-weight : $font-weight-light;
			margin-top  : $gap-base-small;
			order       : 0;
		}

		&-name {
			flex          : 1 0 $event-data-name-size;
			padding-right : $gap-base-small;
		}

		&-value {
			flex : 1 0 (100 - $event-data-name-size);
		}

		//&-name {}

		&-value {
			flex-basis : (100% - $event-data-name-size);
			
			> * {
				> i {
					margin-left : .25em;
				}
			}

			> [data-bind="popover"] {
				cursor  : pointer;
				display : inline-block;
				margin  : 0;

				> i {
					color : $brand-info;
				}

				+ [data-bind="popover"] {
					&::before {
						content : "/";
						padding : 0 .5em;
					}
				}
			}
		}

		@include respond-to(c640) {
			&-name,
			&-value {
				flex-basis : 100% !important;
				width      : 100% !important;
			}

			&-name {
				color     : rgba($color-black, 0.7);
				font-size : .9375em;
			}

			&-value {
				padding-left : 0;
				margin-top   : $margin-base-vertical-xsmall;
			}
		}
	}

	/**
	 * Grundgerüst
	 */

	&__header,
	&__body,
	&__footer {
		padding: $gap-base;

		> :first-child {
			margin-top : 0;
		}

		> :last-child {
			margin-bottom : 0;
		}
	}

	// Nicht genutzt!
	&__header {
		flex : 0 0 auto;

		+ .event__body {
			padding-top : 0;
		}
	}

	&__footer {
		background-color : rgba($color-black, 0.05);
		flex             : 0 0 auto;
		position         : relative;
	}

	&__body {
		flex     : 1 0 auto;
		position : relative;
	}

	/**
	 * Data-Attribute (spezifisch)
	 */

	// Hinweis-Overlay
	[data-event-hint]:not([data-event-hint='']) {
		overflow : hidden;

		&::before {
			background-color : rgba($color-white, 0.9);
			bottom           : 0;
			content          : "";
			display          : none; // Aktiv über Status
			left             : 0;
			position         : absolute;
			right            : 0;
			top              : 0;
			z-index          : 10;
		}

		&::after {
			backface-visibility : hidden;
			color               : $brand-tertiary;
			content             : attr(data-event-hint);
			display             : none; // Aktiv über Status
			font-size           : cast-unit('em', $font-size-xsmall);
			font-weight         : $font-weight-semibold;
			left                : 0;
			line-height         : 1.25em;
			margin              : 0;
			opacity             : 0.75;
			padding             : 0 $gap-base;
			position            : absolute;
			right               : 0;
			text-align          : center;
			top                 : 50%;
			transform           : translate3d(0, -50%, 0);
			z-index             : 11;
		}
	}

	// Status 'im Warenkorb' (global)
	&[data-is-basket] {
		//[data-event-hint] {
		//	&::before,
		//	&::after {
		//		display : block; // Aktiv über Status
		//	}
		//}

		.event-control {
			[data-control="add-basket"] { display : none; }

			[data-control="remove-basket"] { display : block; }
		}
	}

	// Status 'Warteliste'
	&[data-is-waiting] {
		.event__badge {
			background-color : $event-badge-waiting-bg-color;
		}

		.event-control {
			// alle Warenkorb-Buttons ausblenden
			[data-control$="basket"] {
				display : none;
			}

			// Warteliste-Add-Button einblenden
			[data-control="add-waitinglist"] {
				display : block;
			}

			// Warteliste-Remove-Button ausblenden
			[data-control="remove-waitinglist"] {
				display : none;
			}
		}

		// Ist Warteliste aber schon im Warenkorb...
		&[data-is-basket] {
			.event-control {

				// ...dann Warteliste-Add-Button ausblenden
				[data-control="add-waitinglist"] {
					display : none;
				}

				// ...dann Warteliste-Remove-Button einblenden
				[data-control="remove-waitinglist"] {
					display : block;
				}
			}
		}
	}

	&[data-is-fullybooked] {
		.event__badge {
			background-color : $event-badge-fullybooked-bg-color;
		}

		.event-control {
			[data-control]:not([data-control="detail"]) {
				display : none;
			}
		}
	}

	// Nachfolgen Status zeigen die Hinweistext an!
	&[data-is-started], // Status 'gestartet'
	&[data-is-blocked], // Status 'geblockt'
	&[data-is-canceled], // Status 'gebucht'
	&[data-is-booked] { // Status 'abgesagt'
		[data-event-hint] {
			&::before,
			&::after {
				display : block; // Aktiv über Status
			}
		}

		.event-control {
			[data-control]:not([data-control="detail"]) {
				display : none;
			}
		}
	}
}

/** ----------------------------------------------------------------------------
 * Listenansicht
 * -------------------------------------------------------------------------- */

.event-stack {
	align-content   : stretch;
	align-items     : stretch;
	display         : flex;
	flex-basis      : 0;
	flex-grow       : 1;
	flex-shrink     : 1;
	flex-wrap       : wrap;
	justify-content : flex-start;
	margin-left     : -#{$gap-base};
	margin-right    : -#{$gap-base};

	.event {
		min-height : 100%;
	}

	&__item {
		align-self     : stretch;
		flex-basis     : 33.3333333333%;
		flex-direction : row;
		flex-grow      : 0;
		flex-shrink    : 0;
		min-width      : 33.3333333333%;
		overflow       : hidden;
		padding        : $gap-base;
		width          : 33.3333333333%;

		@include respond-to(sm) {
			flex-basis : 50%;
			min-width  : 50%;
			width      : 50%;
		}

		@include respond-to(xs) {
			flex-basis : 100%;
			min-width  : 100%;
			width      : 100%;
		}
	}

	&__loader {
		display : none;
	}
}

/**
 * Nachladbare Events (Boxen)
 */

.event-stack[data-endless] {
	.event-stack {
		//&__item {}

		&__loader {
			flex-basis : 100%;
			margin-top : $gap-base-large;
			min-width  : 100%;
			text-align : center;
			width      : 100%;
			position   : relative;
			display : block;
		}
	}

	.event {
		min-height : 100%;
		transform  : scale(0.8) translate3d(0, 4rem, 0);
		opacity    : 0;
		transition : all .25s ease;

		&[data-is-parsed] {
			opacity   : 1;
			transform : scale(1) translate3d(0, 0, 0);
		}
	}

	.btn[data-load-more] {
		.btn-text {
			visibility : hidden;
		}

		&::after {
			animation          : load-more-rotate 0.8s infinite linear;
			border-color       : #fff;
			border-radius      : 50%;
			border-right-color : transparent !important;
			border-style       : solid;
			content            : '';
			display            : block;
			font-size          : 1.25rem;
			height             : 1em;
			left               : 50%;
			position           : absolute;
			top                : 50%;
			transform          : translate3d(-50%, -50%, 0);
			width              : 1em;
		}
	}
}

//.event-stack[data-endless="loading"] {}

.event-stack[data-endless="loaded"] {
	.btn[data-load-more] {
		.btn-text {
			visibility : visible;
		}

		&::after {
			display   : none;
			animation : none;
		}
	}
}

.event-stack[data-endless="end-reached"] {
	.event-stack__loader,
	.event-stack__loader > * {
		display : none;
	}
}

/** ----------------------------------------------------------------------------
 * Detailansicht
 * -------------------------------------------------------------------------- */

.event-detail {
	.subtitle {
		margin-top : $gap-base-large;
	}

	h3,
	h4 {
		color : $color-black;
	}

	h4 {
		margin-bottom : 0;
	}

	p {
		margin-bottom : $gap-base;
		margin-top    : $gap-base;
	}

	.event__data {
		margin : 0;

		$event-detail-data-name-size : 25%;
		&-name {
			flex          : 1 0 $event-detail-data-name-size;
			padding-right : $gap-base-small;
		}

		&-value {
			flex : 1 0 (100 - $event-detail-data-name-size);
		}

		&.-dates {
			background : #f4f4f4;
			padding    : $gap-base;

			& + .-dates {
				margin-top : $gap-base-large;
			}

			.event__data {
				&-date {
					flex       : 100%;
					margin-top : 0;

					> * {
						font-weight : 700;
						margin-top  : 0;
					}

					i {
						margin-right : $gap-base-small;
						position     : relative;
						top          : -1px;
					}
				}

				$event-detail-data-dates-name-size : 20%;
				&-name {
					flex          : 1 0 $event-detail-data-dates-name-size;
					padding-right : $gap-base-small;
				}

				&-value {
					flex : 1 0 (100 - $event-detail-data-dates-name-size);
				}
			}
		}

		ol,
		ul {
			margin-bottom : $gap-base-xsmall;
			margin-top    : $gap-base-xsmall;

			> li {
				margin-bottom : $gap-base-xsmall;
				margin-top    : $gap-base-xsmall;

				&:first-child {
					margin-top : 0;
				}
				&:last-child {
					margin-bottom : 0;
				}
			}
		}
	}
}
