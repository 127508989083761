/**
 * Extra Small Devices, Phones.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

@include respond-to(xs) {

	#dev > .mq > .xs { display : inline-block; }

	.display-xs-block { display : block; }

	/* =========================================================================
	 *
	 * Vendors
	 *
	 * ====================================================================== */

	.mm-menu.mm-offcanvas {
		width : 70%;
	}

	.fb-custom .fancybox-slide--iframe > .fancybox-content {
		height : 98% !important;
		width  : 96% !important;
	}

	/* =========================================================================
	 *
	 * Komponenten
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'components/_forms'
 	 * ---------------------------------------------------------------------- */

	.form-group > .col-xs-12 {
		margin-bottom : $margin-base-vertical;

		&:last-child { margin-bottom : 0; }
	}

	.checkbox-inline,
	.radio-inline {
		&.display-xs-block { margin-left : 0; }
	}

	/* -------------------------------------------------------------------------
	 * @ 'components/_buttons'
 	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'components/_pagination'
 	 * ---------------------------------------------------------------------- */

	.nav-pagination,
	.pagination { text-align : center; }

	/* -------------------------------------------------------------------------
	 * @ 'components/_lists'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'components/_accordions'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'components/_galleries'
	 * ---------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------
	 * @ 'components/_googlemaps'
	 * ---------------------------------------------------------------------- */

	//.googlemap:not(responsive-16by9):not(responsive-4by3):not(responsive-3by4):not(responsive-3by2):not(responsive-2by3) { height : 300px; }
	.googlemap:not([class*="responsive-"]) { height : 300px; }

	/* -------------------------------------------------------------------------
	 * @ 'components/_articles'
	 * ---------------------------------------------------------------------- */

	.article {
		.edited,
		.datetime { font-size : .9375em; }
	}

	/* -------------------------------------------------------------------------
	 * @ 'components/_stacks'
	 * ---------------------------------------------------------------------- */

	.stack .stack-item {
		.col-xs-12 {
			margin-bottom : $stack-item-gap;

			&:last-child { margin-bottom : 0; }
		}
	}

	/* =========================================================================
	 *
	 * Core
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'core/_type'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'core/_scaffolding'
 	 * ---------------------------------------------------------------------- */

	// Inhaltsspalten
	.main-page {
		padding-bottom : 0;

		> .main {
			> .content,
			> .aside {
				display       : block;
				float         : none;
				padding-left  : 0;
				padding-right : 0;
				width         : 100%;
			}

			> .content { margin-bottom : ($gap-base-large * 2); }

			> .aside {
				background-color : $color-gray-lighter;
				margin-left      : -#{$gap-base};
				margin-right     : -#{$gap-base};
				padding          : ($gap-base-large + $gap-base) 0;
				width            : auto;
			}
		}
	}

	/* -------------------------------------------------------------------------
	 * @ 'core/_navigation'
	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'core/_header'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'core/_footer'
 	 * ---------------------------------------------------------------------- */

	.main-footer {}

	/* -------------------------------------------------------------------------
	 * @ 'core/_sidebar'
	 * ---------------------------------------------------------------------- */

	.aside {
		> * {
			&,
			&:first-child { margin : $gap-base 0; }

			float    : left;
			margin   : $gap-base 0;
			padding  : 0 $gap-base;
			position : relative;
			width    : 33.3333333333%;

			&:nth-child(3n+1) {clear: both;}
		}

		.article {
			background-color : $color-white;
			height: 100%;
		}
	}

	/* -------------------------------------------------------------------------
	 * @ 'core/_showcase'
	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'core/_elements'
	 * ---------------------------------------------------------------------- */

	// Nutzermenü
	.user-bar {
		flex-flow : column wrap;

		&__col {
			&.-menu,
			&.-controls {
				width : 100%;
			}

			&.-menu {
				order : 2;

				.link {
					padding : 0 0 10px 0;

					> .btn__text {
						font-size : 1rem;
					}
				}
			}

			&.-controls {
				order      : 1;
				text-align : right;

				.link,
				.btn {
					padding : 10px 0;
				}
			}
		}
	}

	/* Formulare */
	.form-searchbox {
		.input-group {
			.btn {
				padding-left  : 0;
				padding-right : 0;
				width         : $input-height;

				> .btn-text { display : none; }

				> i { display : block; }
			}
		}
	}

	/* =========================================================================
	 *
	 * Module
	 *
	 * ====================================================================== */

	/* =========================================================================
	 *
	 * Typo3
	 *
	 * ====================================================================== */

}
