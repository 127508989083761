/**
 * Vendors - Fancybox
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/* =============================================================================
 *
 * Variablen.
 *
 * ========================================================================== */

$fb-custom-modal-shadow    : 0 0 6px 2px rgba($color-black, 0.2);
$fb-custom-content-padding : $gap-base;

/* =============================================================================
 *
 * Fancybox-Standard-Definitionen über eigenen Selector setzen.
 *
 * ========================================================================== */

.fb-custom {
	.fancybox {
		&-bg { background-color : $brand-secondary; }

		&-placeholder { box-shadow : 0 0 6px 2px rgba($color-black, 0.2); }

		&-slide {
			> * { padding : $gap-base; }

			> .fancybox-content {
				box-shadow : $fb-custom-modal-shadow;
				margin     : 0;
				max-width  : 600px;
				padding    : $fb-custom-content-padding;
				width      : 95%;
			}

			//&--inline > .fancybox-content,
			//&--iframe > .fancybox-content,
			//&--html > .fancybox-content {
			//	margin     : 0;
			//	box-shadow : $fb-custom-modal-shadow;
			//}

			//&--html > .fancybox-content { max-width : 600px; }

			//&--inline > .fancybox-content {
			//	max-width : 800px;
			//	width     : 95% !important;
			//}

			&--iframe > .fancybox-content {
				height    : 88% !important;
				max-width : 800px;
				padding   : 0;
			}
		}

		&-infobar__body,
		&-button {
			background : $btn-secondary-bg-color;
			color      : $btn-secondary-color;
		}

		&-button {
			&--left,
			&--right { border-radius : 0; }

			&:hover {
				background : $btn-secondary-bg-color-hover;
				color      : $btn-secondary-color-hover;
			}
		}

		&-close-small {
			&:after {
				color          : $brand-secondary;
				content        : "\f00d";
				font-family    : 'Font Awesome 5 Pro';
				font-style     : normal;
				font-weight    : normal;
				line-height    : 30px;
				text-rendering : optimizeSpeed;
			}

			&:hover:after {
				color            : $brand-primary;
				background-color : transparent;
			}
		}

		&-caption-wrap {
			background : none;
			padding    : ($gap-base * 2) 0 0;

			> .fancybox-caption {
				background-color : rgba($brand-secondary, 0.4);
				border-top       : 0 none;
				font-family      : inherit;
				font-size        : 1em;
				line-height      : inherit;
				padding          : $gap-base;
			}
		}
	}

	// Typ, Status
	&.fancybox-{
		&is-open .fancybox-bg { opacity : .7; }

		&is-modal {
			.fancybox-bg { background-color : $color-white; }

			.fancybox-slide > .fancybox-content { width : auto; }

			.fancybox-modal-content {
				min-width : 250px;
			}

			.fb-modal-title {
				margin           : -#{$fb-custom-content-padding} -#{$fb-custom-content-padding} 0 -#{$fb-custom-content-padding};
				background-color : $brand-primary;
				color            : #fff;
				padding          : $fb-custom-content-padding;
				text-align       : center;
				font-size        : 1.125em;
			}

			.fb-modal-message { font-size : cast-unit($font-unit-base, $font-size-small); }

			.fb-modal-controls {
				text-align : center;

				> :first-child { float : left; }
				> :last-child { float : right; }

				> :first-child:last-child { float : none; }
			}

			&.modal-type-info {
				.fb-modal-title { background-color: $brand-info;}
			}

			&.modal-type-warning {
				.fb-modal-title { background-color: $brand-warning;}
			}

			&.-hide-btn {
				&-cancel .fb-modal-controls-cancel { display : none; }

				&-confirm .fb-modal-controls-confirm { display : none; }
			}
		}
	}
}

/* =============================================================================
 *
 * Mediaqueries.
 *
 * ========================================================================== */

@include respond-to(md) { }

@include respond-to(sm) { }

@include respond-to(xs) { }

@include respond-to(c640) {
	.fb-custom {
		&.fancybox- {
			&is-modal {
				.fancybox-slide > .fancybox-content { max-width : 95%; }
			}
		}
	}
}

@include respond-to(c420) { }