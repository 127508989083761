/**
 * Eigener Custom-Breakpoints (640px).
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

@include respond-to(c640) {

	#dev > .mq > .c640 { display : inline-block; }

	.display-c640-block { display : block; }

	.hidden-c640 { display : none; }

	@include grid-append-new-columns('c640', $gap-base);

	//.col-c640-12:not(:last-child) { margin-bottom : $gap-base; }

	/* =========================================================================
	 *
	 * Vendors
	 *
	 * ====================================================================== */

	.mm-menu.mm-offcanvas {
		width : 100%;
	}

	/* =========================================================================
	 *
	 * Komponenten
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'components/_forms'
 	 * ---------------------------------------------------------------------- */

	.checkbox-inline,
	.radio-inline {
		&.display-c640-block { margin-left : 0; }
	}

	/* Formulartypen */
	.form-horizontal {
		$-padding-top : ($gap-base-xsmall + $input-border-width);

		.control-label,
		.radio,
		.checkbox,
		.radio-inline,
		.checkbox-inline { padding-top : $-padding-top; }

		.form-group {
			&-lg .control-label { padding-top : ($gap-base + $input-border-width); }

			&-sm .control-label { padding-top : ($gap-base-xsmall + $input-border-width); }
		}
	}

	/* -------------------------------------------------------------------------
	 * @ 'components/_navs'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'components/_paginations'
 	 * ---------------------------------------------------------------------- */

	.pagination > li {
		&.prev > a > span,
		&.next > a > span { display : none; }
	}

	/* -------------------------------------------------------------------------
	 * @ 'components/_videos-audio'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'components/_lists'
 	 * ---------------------------------------------------------------------- */

	/* Tabellenliste (dt = zeilenkopf, dd = Zeileninhalt) */
	.list-table {
		> * {
			float         : none;
			padding-left  : $gap-base;
			padding-right : $gap-base;
		}

		> dt,
		> li:nth-child(2n+1) {
			padding-bottom : $gap-base-small;
			width         : 100%;
		}

		> dd,
		> li:nth-child(2n+2) {
			border-top : 0 none;
			padding-top : 0;
			width      : 100%;
		}
	}

	/* -------------------------------------------------------------------------
	 * @ 'components/_galleries'
	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'components/_googlemaps'
	 * ---------------------------------------------------------------------- */

	/* =========================================================================
	 *
	 * Core
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'core/_type'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'core/_scaffolding'
 	 * ---------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------
	 * @ 'core/_navigation'
 	 * ---------------------------------------------------------------------- */

	// Hauptmenü
	.nav-primary-mobile.mm-menu { border-right : 0 none;}

	/* -------------------------------------------------------------------------
	 * @ 'core/_header'
 	 * ---------------------------------------------------------------------- */

	.main-header {
		.logo {
			&.-site {
				font-size : 2.5rem;
			}

			&.-client {
				font-size : 4rem;
			}
		}
	}

	/* -------------------------------------------------------------------------
	 * @ 'core/_footer'
 	 * ---------------------------------------------------------------------- */

	.main-footer {
		dl.sponsors-supporters {
			clear : both;
			float : none;
			width : 100%;
		}
	}

	/* -------------------------------------------------------------------------
	 * @ 'core/_sidebar'
 	 * ---------------------------------------------------------------------- */

	.aside {
		> * {
			width : 50%;

			&:nth-child(3n+1) { clear : none; }

			&:nth-child(2n+1) { clear : both; }
		}
	}

	/* -------------------------------------------------------------------------
	 * @ 'core/_showcase'
	 * ---------------------------------------------------------------------- */

	.main-showcase {
		> .bar-s .col-gallery { margin-top : 0; }

		.gallery-text-parts {
			.swiper-slide-wrapper { padding : 0; }

			.article {
				> .article {
					&-header,
					&-body {
						float            : none;
						width            : 100%;
						padding: 0;
					}

					&-header { margin-bottom : $gap-base-small; }

				}
			}
		}
	}

	/* -------------------------------------------------------------------------
	 * @ 'core/_elements'
	 * ---------------------------------------------------------------------- */

	// Nutzermenü
	.user-bar {
		&__col {
			> * {
				margin-right : ($gap-base + $gap-base-xsmall);
			}

			&.-menu {
				.link {
					> i {
						background-color : $color-gray-dark;
						color            : $color-white;
						border-radius    : 1em;
						display          : block;
						padding          : .5em 0;
						text-align       : center;
						width            : 2em;
					}
				}
			}
		}
	}

	/* =========================================================================
	 *
	 * Module
	 *
	 * ====================================================================== */

	/* =========================================================================
	 *
	 * Views
	 *
	 * ====================================================================== */

	/* -------------------------------------------------------------------------
	 * @ 'views/index/_index'
	 * ---------------------------------------------------------------------- */

}
