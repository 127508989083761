$rs-bg           : $color-white !default;
$rs-border-color : $color-gray-light !default;
$rs-color        : $color-gray !default;
$rs-padding      : 8px;

// Hover colors
$rs-bg-hover           : $color-white !default;
$rs-border-color-hover : $rs-border-color !default;
$rs-color-hover        : $brand-primary !default;

// Active colors
$rs-bg-active           : $brand-secondary !default;
$rs-border-color-active : $brand-secondary !default;
$rs-color-active        : $color-white !default;

//
// Playbutton
//

$rs-play-bg : $brand-secondary !default;
$rs-play-border-color : $brand-secondary !default;
$rs-play-color : $color-white !default;

$rs-play-bg-hover : $btn-secondary-bg-color-hover !default;
$rs-play-border-color-hover : $btn-secondary-bg-color-hover !default;
$rs-play-color-hover : $color-white !default;

$rs-play-bg-active :$rs-play-bg-hover !default;
$rs-play-border-color-active : $rs-play-border-color-hover !default;
$rs-play-color-active : $rs-play-color-hover !default;

//
// Popup
//

$rs-popup-bg : $brand-primary !default;
$rs-popup-border-color : $brand-primary !default;
$rs-popup-color : $color-white !default;

$rs-popup-bg-hover : $brand-secondary !default;
$rs-popup-border-color-hover : $brand-secondary !default;
$rs-popup-color-hover : $color-white !default;

$rs-popup-bg-active :$rs-play-bg-hover !default;
$rs-popup-border-color-active : $rs-play-border-color-hover !default;
$rs-popup-color-active : $rs-play-color-hover !default;

//
// Section
//
.main-page.-transparent {
	.section-readspeaker {
		background    : $color-white;
		margin-bottom : 0 !important;
		padding-top   : 1.25em;
	}
}

.section-readspeaker {
	display       : flex;
	margin-bottom : -30px;
}

.event-detail .section-readspeaker {
	margin-bottom : 16px;
	
	> * {
		padding : 0 !important;
	}
}

//
// Highlighted Textparts
//

.sync {
	&_sent,
	&_word {
		&,
		&_highlighted {
			font-family : inherit !important;
			font-size   : inherit !important;
		}
	}
}

//
// Visuelle Anpassung ReadSpeaker-UI
//

.rsbtn_toollist,
.rsform {
	li:before {
		content     : ""; /* stylelint-disable-line string-quotes */
		font-family : inherit;
	}
}

.rsbtn_tools {
	.rsbtn_powered {
		height      : 23px !important;
		margin-left : 8px !important;
		position    : relative !important;
		top         : 0 !important;
	}
}

.rsbtn.fs {
	$-root : &;
	
	margin         : 0;
	padding-bottom : 0;
	
	+ .rsbtn_label {
		display : none;
	}
	
	a,
	button {
		&:focus {
			outline        : 1px dotted $color-black !important;
			outline-offset : 1px !important;
		}
	}
	
	// Ladestatus
	.rs-player-loadindicator {
		background : $brand-secondary;
		
		.rsbtn-loading {
			background : $brand-tertiary;
		}
		
		.rsbtn-progress {
			background : $brand-primary;
		}
	}
	
	// Playbutton
	.rsbtn_play {
		background-color : $rs-play-bg;
		border-color     : $rs-play-border-color;
		border-radius    : 4px;
		color            : $rs-play-color;
		margin-bottom    : 0;
		
		.rsbtn_left {
			margin-left : 16px;
			
			.rsbtn_text {
				background : transparent !important;
				margin     : 0;
				
				&::before {
					color : $rs-play-color;
				}
				
				.rsbtn_label {
					color : $rs-play-color;
				}
			}
		}
		
		.rsbtn_right {
			border-color : $color-white;
			width        : 26px;
			
			&::before {
				color : $rs-play-color;
			}
		}
		
		&:hover,
		&:focus {
			background-color : $rs-play-bg-hover;
			
			.rsbtn_left {
				.rsbtn_text {
					&::before {
						color : $rs-play-color-hover;
					}
					
					.rsbtn_label {
						color : $rs-play-color-hover;
					}
				}
			}
			
			.rsbtn_right {
				&::before {
					color : $rs-play-color-hover;
				}
			}
		}
	}
	
	// Einstellungstoggle
	.rsbtn_tooltoggle {
		display : none !important;
		
		background    : transparent;
		border-color  : transparent;
		border-radius : 4px 4px 4px 8px;
		color         : $rs-play-color;
		left          : 0;
		overflow      : hidden;
		transition    : color 0.25s, background-color 0.25s, border-color 0.25s;
		
		&:hover,
		&:focus {
			background   : transparent;
			border-color : transparent;
			color        : $rs-play-color-hover;
		}
	}
	
	&.zoom-tooltoggler {
		.rsbtn_tooltoggle {
			&,
			&:active {
				background   : $rs-play-bg-active;
				border-color : $rs-play-border-color-active;
				color        : $rs-play-color-active;
				
				// Reset für normales kleines Icon
				font-size    : 12px;
				height       : 14px;
				left         : 0;
				line-height  : 12px;
				top          : 14px;
				width        : 16px;
			}
		}
	}
	
	// Playersteuerung(en)
	.rsbtn_exp {
		.rsbtn_exp_inner {
			background-color           : $rs-bg;
			border-bottom-right-radius : 8px;
			border-color               : $rs-border-color-active;
			border-top-right-radius    : 8px;
			
			// Statusmeldung
			.rsbtn_status_overlay {
				background-color : $color-white;
				left             : 0;
				
				.rsbtn_status {
					padding-left  : 8px;
					padding-right : 8px;
					text-align    : center;
					
					&,
					> * {
						color : $brand-danger;
					}
				}
			}
			
			// Steuerelement
			.rsbtn_player_item {
				background        : transparent;
				border-left-color : rgba($rs-border-color-active, 0.5);
				color             : $rs-color;
				
				&:hover,
				&:focus {
					background        : $rs-bg-hover;
					border-left-color : rgba($rs-border-color-active, 0.5);
					color             : $rs-color-hover;
				}
			}
			
			// Schließen-Buttons
			.rsbtn_closer:hover i.rsicn::before {
				content : "\e90b"; /* stylelint-disable-line string-quotes */
			}
		}
	}
	
	// Tools aktiv/geöffnet
	&.toolsopen {
		z-index : 10;
		
		// Einstellungstoggle
		.rsbtn_tooltoggle {
			background   : $rs-bg-active;
			border-color : $rs-border-color-active;
			color        : $rs-color-active;
		}
	}
	
	// Player aktiv/geöffnet
	&.rsexpanded {
		// Play-Button
		.rsbtn_play {
			background-color : $rs-bg-active;
			border-color     : $rs-border-color-active;
			color            : $rs-color-active;
			
			.rsbtn_left {
				.rsbtn_text {
					&::before {
						color : $rs-color-active;
					}
					
					.rsbtn_label {
						color : $rs-color-active;
					}
				}
			}
			
			.rsbtn_right {
				border-color : $rs-border-color-active;
				
				&::before {
					color : $rs-color-active;
				}
			}
		}
	}
}

body .rspopup {
	background-color : $rs-popup-bg;
	border           : 0 none;
	box-shadow       : none;
	color            : $rs-popup-color;
	display          : block !important;
	
	.rspopup_btn {
		border-color : $color-white;
		
		&:hover,
		&:focus {
			background-color : $rs-popup-bg-hover;
			color            : $rs-popup-color-hover;
		}
	}
}


//@include media-breakpoint-up('m') {
//	.c-header + .c-rspeak {
//		margin-top : -16px;
//	}
	
	//.c-rspeak {
	//	.rsbtn {
	//		> .rsbtn_play {
	//			font-size   : 1rem;
	//			height      : auto;
	//			line-height : 1;
	//			display     : inline-flex;
	//
	//			.rsbtn_ {
	//				&left,
	//				&right {
	//					height      : auto;
	//					line-height : normal;
	//
	//					.rsbtn_text {
	//						height : auto;
	//
	//						&::before,
	//						span {
	//							font-size: inherit;
	//							height: auto;
	//							line-height: normal;
	//						}
	//					}
	//				}
	//
	//				&left .rsbtn_text {
	//					&::before {
	//						top: 50%;
	//						transform: translateY(-50%);
	//					}
	//				}
	//			}
	//		}
	//	}
	//}
//}
