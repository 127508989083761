/**
 * Module
 *
 * @copyright 2018 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */


/**
 * Box mit weißem Hintergrund
 */

.box {
	align-content    : flex-start;
	align-items      : stretch;
	background-color : $color-white;
	border           : 2px solid $color-gray;
	display          : flex;
	flex-direction   : column;
	flex-wrap        : nowrap;
	font-size        : 1rem;
	justify-content  : flex-start;
	margin-left      : auto;
	margin-right     : auto;
	max-width        : 660px;

	@include respond-to(xs) {
		max-width : none;
	}

	> form {
		align-content   : flex-start;
		align-items     : stretch;
		display         : flex;
		flex-direction  : column;
		flex-wrap       : nowrap;
		justify-content : flex-start;
		margin          : 0;
		min-height      : 100%;
		padding         : 0;
	}

	&__header,
	&__footer {
		order      : 0;
		flex       : 0 1 auto;
		align-self : auto;
	}

	&__header {
		padding: ($gap-base * 2);

		> * {
			margin-top: $gap-base;

			&:first-child { margin-top : 0; }

			&:last-child { margin-bottom : 0; }
		}

		+ .box__body { padding-top : 0; }

		@include respond-to(xs) {
			padding: $gap-base;
		}
	}

	&__body {
		align-self : auto;
		flex       : 1 0 auto;
		order      : 0;
		padding    : ($gap-base * 2);

		> :first-child {
			margin-top : 0;
		}

		> :last-child {
			margin-bottom : 0;
		}

		@include respond-to(xs) {
			padding: $gap-base;
		}
	}

	&__footer {
		background-color : transparent;
		padding          : $gap-base ($gap-base * 2);
		text-align       : center;

		@include clearfix;

		[data-btn-prev] {
			float : left;
		}

		[data-btn-next] {
			float : right;
		}

		//&.-ready {
		//	background-color : rgba($brand-primary, 0.2);
		//}

		@include respond-to(xs) {
			padding-left  : $gap-base;
			padding-right : $gap-base;
		}
	}
}

.box-stack {
	align-content   : flex-start;
	align-items     : flex-start;
	display         : flex;
	flex-direction  : row;
	flex-wrap       : wrap;
	justify-content : space-between;
	margin          : -#{$gap-base};

	&__item {
		order      : 0;
		flex       : 0 1 50%;
		align-self : stretch;

		> .padding-hack {
			padding : $gap-base;
			height  : 100%;

			> * {
				height : 100%;
			}
		}

		@include respond-to(xs) {
			flex-basis : 100%;
			width      : 100%;
		}
	}

	&.-sidebar-view {
		.box {
			max-width : none;
		}

		> *:nth-child(2n+1) {
			flex-basis : 66%;
		}

		> *:nth-child(2n) {
			flex-basis : 34%;
		}

		@include respond-to(xs) {
			> *:nth-child(1n) {
				flex-basis : 100%;
			}
		}
	}
}

/**
 * Global
 */

.module {
	align-content   : stretch;
	align-items     : flex-start;
	display         : flex;
	flex-direction  : column;
	flex-wrap       : nowrap;
	font-size       : 1rem;
	justify-content : flex-start;

	&__header,
	&__footer {
		align-self : stretch;
		flex       : 0 1 auto;
	}

	&__header,
	&__footer,
	&__body {
		> .wrap {
			margin-left  : auto;
			margin-right : auto;
			max-width    : $website-max-width;
			padding      : 0 $gap-base;
			width        : $website-default-width;
		}
	}

	&__header {
		$-border-size : 4px;
		$-padding-y   : $gap-base-small * 2;

		background-color : $color-white;
		border-bottom    : cast-unit($font-unit-base, $-border-size) solid $color-gray;

		> .wrap {
			padding-bottom : cast-unit($font-unit-base, $-padding-y);
			padding-top    : cast-unit($font-unit-base, $-padding-y);

			> :first-child {
				margin-top : 0;
			}

			> :last-child {
				margin-bottom : 0;
			}
		}

		.step-progress {
			$-offset : cast-unit($font-unit-base, ($-padding-y + $-border-size));
			margin : 0 0 -#{$-offset};
		}

		.title {
			margin    : 0;
			font-size : cast-unit('em', $h2-font-size);
		}

	}

	&__footer {
		background-color : rgba($brand-primary, 0.2);

		> .wrap {
			padding-top    : $gap-base;
			padding-bottom : $gap-base;
			text-align     : center;
		}
	}

	&__body {
		flex           : 1 0 auto;
		align-self     : stretch;
		padding-bottom : ($gap-base-large * 2);
		padding-top    : ($gap-base-large * 2);
	}

	&[data-module-blocked] .module__footer {
		background-color : $color-gray-light;
	}
}

/**
 * Imports
 */

@import
	'_event-search',
	'_steps',
	'_qualification',
	'_basket',
	'_event-overlay';