/**
 * ScCookie
 */

// Import Vendor-CSS.
@import '../../vendors/plugins/sc-cookie/css/_sc-cookie';

// Custom-CSS.
.sc-cookie {
	--sccookie-color-primary-h : #{get-hue($brand-secondary)};
	--sccookie-color-primary-s : #{get-saturation($brand-secondary)};
	--sccookie-color-primary-l : #{get-lightness($brand-secondary)};
	--sccookie-font-size       : 0.875rem;
	--sccookie-z-index         : 10000;

	// Footer
	--sccookie-footer-background-color : #{$brand-secondary};
	--sccookie-footer-color            : #{$color-white};

	// Trigger
	--sccookie-trigger-size : 2.25rem;

	&__footer {
		--sccookie-link-color : #{$color-white};
	}

	&-table tr {
		> th {
			background-color : transparent;
			font-size        : inherit;
		}
	}
}

button.sc-cookie-trigger {
	background-color : var(--sccookie-color-white);

	svg {
		height : 90%;
		margin : 0 auto;
		width  : 90%;
	}
}

button[data-trigger="consent-settings"] {
	background-color : transparent;
	border           : 0;
	color            : inherit;
	font-weight      : inherit;
	padding          : 0;
}

.standalone-page .sc-cookie {
	&-trigger,
	&-banner {
		display : none !important;
	}
}
