/**
 * Seitenkopf
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

$logo : (
	'src'    : '../images/logo.png',
	'height' : 346px,
	'width'  : 500px
);


.main-header {
	background     : $main-header-bg-color;
	padding-bottom : ($padding-base-vertical * 2);
	padding-top    : ($padding-base-vertical * 2);
	position       : relative;
	transition     : all .25s ease;

	.logo {
		backface-visibility : hidden;
		display             : inline-block;
		font-size           : 1rem;
		margin              : 0;
		overflow            : hidden;
		transform           : translateZ(0);
		transform-style     : flat;
		transition          : all .25s ease;

		> * {
			$-img-w : (calc-aspect-ratio-new(500px, 202px, 0, $font-size-base) + 0px);

			background-position : center;
			background-repeat   : no-repeat;
			background-size     : contain;
			display             : block;
			font-size           : inherit;
			height              : 0;
			overflow            : hidden;
			padding-top         : 1em;
			text-indent         : 150%;
			white-space         : nowrap;
			width               : em($-img-w, $font-size-base);
		}

		&.-site {
			background : transparent url(../images/logo/logo_bildungsportal.svg) no-repeat 0 0;
			//font-size  : 5rem;
			margin     : 0 0 0 auto !important;
		}

		&.-client {
			font-size : 6.5rem;

			&:last-child {
				margin-left : auto !important;
			}

			&.-lsbbb {
				> * {
					background-image : url(../images/logo/logo_lsbbb_hoch.png);
				}
			}

			&.-esab {
				> * {
					background-image : url(../images/logo/logo_esab.png);
				}
			}

			&.-aok {
				> * {
					background-image : url(../images/logo/logo_aok.png);
				}
			}

			> * {
				$-img-w : (calc-aspect-ratio-new(500px, 346px, 0, $font-size-base) + 0px);

				width : em($-img-w, $font-size-base);
			}
		}
	}

	/* Flexverhalten */
	> .main-header-wrap {
		$-sbox-basis : 47%;

		align-content   : stretch;
		align-items     : stretch;
		display         : flex;
		flex-flow       : row nowrap;
		justify-content : end;
		line-height     : 1; // Entfernt unnötigen Innenabstand der Flex-Kindelemente.

		.logo {
			align-items     : center;
			align-self      : center;
			flex            : 0 1 auto;
			justify-content : center;
			margin          : 0;
			order           : 2;
			padding         : 0;
			
			@include respond-to(c640) {
				> * {
					width : 1.25em;
				}
			}

			& + .logo {
				margin : 0 0 0 $gap-base-large;
				
				@include respond-to(c640) {
					margin : 0 10px 0 10px !important;
				}
			}
			
			&:first-child {
				@include respond-to(c640) {
					margin-left : 0 !important;
				}
			}
			
			&:last-child {
				@include respond-to(c640) {
					margin-right : 0 !important;
				}
			}

			&.-client {
				order : 1;
			}
		}
	}
}
