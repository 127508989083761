/*!
 *
 *  nam.PORT | FBM
 *
 *  @author Erik Reuter
 *  @copyright 2022 i-fabrik GmbH
 *  @version $Id: _event-overlay.scss,v 2.221107 2022/11/07 08:49:21 $
 *
 */

/*!
 *
 *  nam.PORT | FBM
 *
 *  @author Tobias Garz
 *  @copyright 2022 i-fabrik GmbH
 *
 */

/**
 * Event-Overlay
 */

.event-overlay {
	align-items     : center;
	display         : flex;
	height          : 100%;
	justify-content : center;
	left            : 0;
	opacity         : 0;
	position        : fixed;
	top             : 0;
	transform       : scale(0);
	transition      : all 0.5s ease-in-out;
	width           : 100%;
	z-index         : 2;
	
	&.-show {
		opacity   : 1;
		transform : scale(1);
		z-index   : 11;
	}
	
	&__cover {
		background : $brand-secondary;
		height     : 100%;
		left       : 0;
		opacity    : 0.7;
		position   : absolute;
		top        : 0;
		width      : 100%;
	}
	
	&__inner {
		background    : $color-white;
		border-radius : 12px;
		padding       : $gap-base-large;
		position      : relative;
		z-index       : 1;
	}
	
	&__close {
		$dim : 40px;
		background    : $brand-secondary;
		border-radius : 50%;
		color         : $color-white;
		cursor        : pointer;
		height        : $dim;
		position      : absolute;
		right         : 0;
		text-align    : center;
		top           : 0;
		transform     : translate3d(25%, -25%, 0);
		width         : $dim;
		z-index       : 2;
		
		&:hover {
			background : $brand-primary;
		}
		
		> * {
			line-height : $dim;
		}
	}
	
	&__header {
		> * {
			&:first-child {
				margin-top : 0;
			}
		}
	}
	
	&__content {
		display        : flex;
		flex-direction : column;
		gap            : $gap-base-small;
		
		> .btn {
			> i {
				margin-right : $gap-base-small;
			}
		}
	}
}