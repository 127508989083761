/**
 * Icons
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/* =============================================================================
 *
 * Hilfsklasse fuer '@extend' (Iconschriften: 'FontAwesome').
 *
 * ========================================================================== */

.fa-icon-helper {
	display        : inline-block;
	font-family    : 'Font Awesome 5 Pro';
	font-size      : inherit;
	font-style     : normal;
	font-weight    : normal;
	line-height    : 1;
	text-rendering : optimizeSpeed;
}