/**
 * Grundaufbau, -raster
 *
 * Definitionen.
 *
 * (1) Nur wenn eine bestimmte Variable existiert wird auch eine entsprechende
 *     Defintionen integriert.
 * (2) Pruefen auf korrekten Variablentyp, -inhalt.
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

html {
	-moz-osx-font-smoothing  : grayscale;
	-ms-text-size-adjust     : 100%;
	-webkit-font-smoothing   : antialiased;
	-webkit-text-size-adjust : 100%;
	font-size                : $body-font-size;
}

body,
html {
	height  : 100%;
	margin  : 0;
	padding : 0;
}

body {
	background     : {
		attachment : fixed;
		color      : $color-white;
		repeat     : no-repeat;
		size       : cover;
	}
	color      : $body-text-color;
	font       : $body-font-style $body-font-variant $body-font-weight #{$body-font-size}/#{$line-height-base} $body-font-family;
	text-align : $body-text-align;
}

.show-on-print { display : none; }

/* =============================================================================
 *
 * Grundcontainer der Seite
 *
 * ========================================================================== */

.page-max-width {
	margin-left   : auto;
	margin-right  : auto;
	max-width     : $website-max-width;
	padding-left  : $gap-base;
	padding-right : $gap-base;
	width         : $website-default-width;
}

// Standard
.main-wrapper {
	display        : flex;
	flex-direction : column;
	min-height     : 100%;

	> .main {

		&-page {
			flex     : 1 0 auto;
			position : relative;
			//z-index  : 10;
		}

		&-header,
		&-breadcumb,
		&-showcase,
		&-footer { flex : 0 0 auto; }

		&-header {
			@extend .page-max-width;
			//z-index : 20;
		}

		&-meta {
			background-color : $brand-secondary;
			position         : relative;
			//z-index          : 15;

			> * { @extend .page-max-width; }
		}

		//&-showcase {
		//	margin-bottom : $gap-base-large;
		//	z-index       : 12;
		//}

		&-breadcumb {
			z-index : 5;

			> * { @extend .page-max-width; }
		}

		&-footer {
			//z-index : 1;

			> .service,
			> .meta > .nav { @extend .page-max-width; }
		}
	}

	.fixed-extern {
		top        : 0;
		padding    : 10px;
		position   : relative;
		text-align : right;
		width      : 100%;
		z-index    : 99;

		@media screen and (min-width : 1700px) {
			position : sticky;
		}

		> a.btn {
			border-radius : 15px;
			position      : relative;

			@media screen and (min-width : 1700px) {
				top : 105px;
			}

			> p {
				margin-bottom : 5px;
				margin-top    : 0;
			}
		}
	}
}

// Inhaltsspalten
.main-page {
	background-color : $color-white;

	&.-transparent {
		background-color : $color-gray-lighter;
	}

	//> .main {
	//	$-width-aside : 30%;
	//
	//	width   : 100%;
	//	z-index : 1;
	//
	//	> * { float : left; }
	//
	//	> .content {
	//		float : left;
	//		width : (100% - $-width-aside);
	//
	//		&:first-child:last-child { width : 100%; }
	//	}
	//
	//	> .aside {
	//		float        : left;
	//		padding-left : ($gap-base-large * 2) - $gap-base;
	//		width        : $-width-aside;
	//	}
	//}
}

.main-modal-iframe-wrapper {
	padding : ($gap-base * 3);

	@include respond-to(c640) {
		padding : $gap-base;
	}

	> .main-modal-footer {
		margin-top : ($gap-base * 2);
		text-align : center;
	}

	.module {
		&__header, &__footer, &__body {
			> .wrap {
				max-width    : none;
				padding      : 0;
			}
		}

		&__body {
			padding : 0;
		}
	}
}

/* =============================================================================
 *
 * Sektionen, Bereiche
 *
 * ========================================================================== */

.section {
	padding : ($gap-base-large * 2) 0;

	&__header,
	&__body {
		margin-left  : auto;
		margin-right : auto;
		max-width    : $website-max-width;
		padding      : 0 $gap-base;
		width        : $website-default-width;

		> :first-child { margin-top: 0; }

		> :last-child { margin-bottom: 0; }
	}

	&__header { margin-bottom : $gap-base; }

	&.-dark {
		background-color : $color-gray-lighter;
		padding-bottom   : $gap-base-large;
		padding-top      : $gap-base-large;
	}

	// Introbereich
	//&.-type-intro {}
}

/* =============================================================================
 *
 * Info ueber aktuellen Mediaquery.
 *
 * ========================================================================== */

@if ($debug-mode) {
	#dev {
		background-color : #e6e6e6;
		color            : #fff;
		cursor           : $cursor-default;
		font-size        : 11px;
		left             : 50%;
		margin-left      : -120px;
		opacity          : .25;
		position         : fixed;
		top              : 0;
		width            : 240px;
		z-index          : 99990;

		> * {
			border-top : 1px solid #fff;

			&:first-child { border-top : 0 none; }
		}

		strong,
		span {
			border      : 1px solid transparent;
			display     : inline-block;
			font-weight : normal;
			height      : 20px;
			line-height : 18px;
			padding     : 0 4px;
		}

		strong {
			background-color : #343434;
			border-color     : #343434;
			color            : #fff;
			width            : 55px;
		}

		span {
			margin-left : 1px;
			min-width   : 35px;
			text-align  : center;
		}

		.mq > span {
			background-color : #929292;
			border-color     : #929292;
			display          : none;
		}

		.themes > span {
			background-color : transparent;
			border-color     : #929292;
			color            : #929292;
			cursor           : $cursor-pointer;

			&.active {
				background-color : #ffc107;
				border-color     : #ffc107;
				color            : #fff;
			}
		}

		&:hover { opacity : 1; }
	}

	.dev-delimiter {
		border-bottom  : 1px solid $color-gray;
		padding-bottom : $gap-base-small;
		text-transform : uppercase;
	}
} @else {
	#dev,
	.dev-delimiter { display : none !important; }
}
